<template>
  <div class="content">
    <div class="m-card">
      <Tabs v-model="showTabs" @on-click="switchBrand">
        <TabPane :label="$t('lang.Mine') + '（' + count.pub + '）'" name="1">
          <pub v-if="String(showTabs) === '1'" @imgOpen="imgOpen"></pub>
        </TabPane>
        <TabPane :label="$t('lang.Following') + '（' + count.follow + '）'" name="2">
          <follow v-if="String(showTabs) === '2'" @imgOpen="imgOpen"></follow>
        </TabPane>
      </Tabs>
    </div>
    <ShowSwiper :show="imgShow" :imgList="imgList"></ShowSwiper>
  </div>
</template>

<script>
import pub from "./components/needs/my-pub";
import follow from "./components/needs/my-follow";
import ShowSwiper from "@components/ShowSwiper/ShowSwiper";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "layout",
  data() {
    return {
      showTabs: "1",
      actTabs: "1",
      count: {
        pub: 0,
        follow: 0,
      },
      imgShow: false,
      imgList: [],
    };
  },
  components: {
    pub,
    follow,
    ShowSwiper,
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      userInfo: "getUser",
    }),
  },
  created() {
    this.getCountWarp();
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
    }),
    imgOpen(imgList) {
      this.imgList = imgList;
      this.$nextTick(() => {
        this.imgShow = true;
      });
    },
    closeSwiper() {
      this.imgShow = false;
    },
    getCountWarp() {
      this.getCount("pub");
      this.getCount("follow");
    },
    async getCount(type) {
      let query = ``;
      let where = {
        Equal: {
          n: "memberId",
          v: this.userInfo.inMember.memberId,
        },
      };
      if (type == "pub") {
        query = `
          query($query:QueryInput!){
            supplyDemandQuery{
              query(query:$query){
                totalCount
              }
            }
          }
        `;
        where.EqualB = { n: "exhibitionId", v: this.exhibitionId }; // 已回应
      } else if (type == "follow") {
        query = `
          query($query:QueryInput!){
            supplyDemandFollowQuery{
              queryCustom(query:$query,exhibitionId:"${this.exhibitionId}"){
                totalCount
              }
            }
          }
        `;
      }
      let opt = {
        query: query,
        variables: {
          query: {
            page: 10000,
            size: 1,
            where: JSON.stringify(where),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = {};
      if (type == "pub") {
        res = JSON.parse(JSON.stringify(data.data.supplyDemandQuery.query));
      } else if (type == "follow") {
        res = JSON.parse(JSON.stringify(data.data.supplyDemandFollowQuery.queryCustom));
      }

      if (res && res.totalCount) {
        this.count[type] = res.totalCount;
      }
    },
    switchBrand(name) {
      if (this.actTabs != this.showTabs) {
        this.actTabs = this.showTabs;
        this.getCountWarp();
      }
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.content {
  .m-card {
    min-height: 700px;
    border: solid 1px #ededed;
    .mc-top-title::before {
      opacity: 0;
      font-size: 16px;
    }
  }
}
</style>
